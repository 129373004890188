import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { ButtonPrimaryComponent } from '../../components/button-primary/button-primary.component';
import { InputComponent } from '../../components/input/input.component';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SecondaryLinkComponent } from '../../components/secondary-link/secondary-link.component';
import { environment } from '../../../environments/environment';
import { FormSectionComponent } from '../../components/form-section/form-section.component';
import { SelectComponent } from '../../components/select/select.component';
import { EventDirective } from '../../event.directive';
import { CountryService } from '../../country.service';
import { SalutationService } from '../../salutation.service';
import { CustomerStateService } from '../customer-state.service';
import { ensureValidity } from '../../form';
import { CheckboxComponent } from '../../components/checkbox/checkbox.component';
import { isApiClientError } from './is-api-client.error';

@Component({
  selector: 'app-registration-form',
  imports: [
    ButtonPrimaryComponent,
    InputComponent,
    ReactiveFormsModule,
    SecondaryLinkComponent,
    FormSectionComponent,
    SelectComponent,
    EventDirective,
    CheckboxComponent,
  ],
  templateUrl: './registration-form.component.html',
  styleUrl: './registration-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationFormComponent implements OnInit {
  readonly fb = inject(NonNullableFormBuilder);
  readonly customerState = inject(CustomerStateService);
  readonly country = inject(CountryService);
  readonly salutation = inject(SalutationService);

  registered = output<void>();
  initialEmail = input<string>();

  countries = this.country.store;
  salutations = this.salutation.store;
  errorMessage = signal<string>('');

  form = this.fb.group({
    salutationId: this.fb.control('', [Validators.required]),
    firstName: this.fb.control('', [Validators.required]),
    lastName: this.fb.control('', [Validators.required]),
    email: this.fb.control('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$'),
    ]),
    countryId: this.fb.control('', [Validators.required]),
    street: this.fb.control('', [Validators.required]),
    city: this.fb.control('', [Validators.required]),
    zipcode: this.fb.control('', [Validators.required]),
    dataProtection: this.fb.control(false, [Validators.requiredTrue]),
    password: this.fb.control<string>('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  constructor() {
    effect(() => {
      const salutations = this.salutations();
      if (!salutations.loading && !this.form.controls.salutationId.value) {
        this.form.controls.salutationId.patchValue(salutations.elements[0].id!);
      }
    });
    effect(() => {
      const countries = this.countries();
      if (!countries.loading && !this.form.controls.countryId.value) {
        this.form.controls.countryId.patchValue(countries.elements[0].id!);
      }
    });
  }

  ngOnInit() {
    const email = this.initialEmail();
    if (email) {
      this.form.patchValue({ email });
    }
  }

  register = async () => {
    if (!ensureValidity(this.form, { scroll: true })) {
      return;
    }

    const value = this.form.getRawValue();
    try {
      await this.customerState.register({
        accountType: 'private',
        password: value.password,
        email: value.email,
        acceptedDataProtection: value.dataProtection,
        salutationId: value.salutationId,
        firstName: value.firstName,
        lastName: value.lastName,
        guest: false,
        storefrontUrl: environment.storefrontUrl,
        billingAddress: {
          street: value.street,
          zipcode: value.zipcode,
          city: value.city,
          countryId: value.countryId,
        },
      });
      this.registered.emit();
    } catch (e) {
      // if (e instanceof ApiClientError) {
      //
      // }
      if (isApiClientError(e)) {
        if (
          e.details.errors.some(
            (e) => e.code === 'VIOLATION::CUSTOMER_EMAIL_NOT_UNIQUE',
          )
        ) {
          this.errorMessage.set(
            'Email bereits registriert, bitte verwenden Sie eine andere Email-Adresse oder loggen Sie sich ein.',
          );
        } else {
          this.errorMessage.set(
            'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
          );
        }
      }
    }
  };
}
